import Swiper from "swiper/bundle";
import { triggerAnalyticsEvent } from '../shared/analytics'
import { ANALYTICS_EVENT } from "./analyticsEvent";

const swiperPosts = new Swiper(".posts .swiper", {
  slidesPerView: 1,
  loop: true,

  navigation: {
    nextEl: ".posts .button-next",
    prevEl: ".posts .button-prev",
  },

  autoplay: {
    delay: 3000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },

  pagination: {
    el: ".posts .swiper-pagination",
    clickable: true,
  },

  on: {
    sliderFirstMove: () => { triggerAnalyticsEvent(ANALYTICS_EVENT.BlogPost) },
    navigationNext: () => { triggerAnalyticsEvent(ANALYTICS_EVENT.BlogPost) },
    navigationPrev: () => { triggerAnalyticsEvent(ANALYTICS_EVENT.BlogPost) }
  }
});
